import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import Box from '@/components/ui/box/box.vue';
import RadialProgressBar from 'vue-radial-progress';
import LineChart from '@/components/ui/charts/line-chart/lineChart.vue';
import { ChartData, ChartOptions } from 'chart.js';
import { getDatesArrayInterval, MD_FORMAT } from '@/helpers/date.helper';
import { DateTime } from 'luxon';

const title = 'dashboard.title';

@Component({
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    Box,
    RadialProgressBar,
    LineChart
  },
} as any)
export default class Dashboard extends Vue {
  title = title;
  headerBlocks: any = [];
  favoriteBlocks: any = [];
  completedSteps = 76;
  totalSteps = 100;
  chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    showLines: true
  };
  incomeChartData: ChartData | null = null;

  created(): void {
    this.headerBlocks = [
      { id: 1, value: 11549, title: this.$t('dashboard.visitCountToday'), class: 'mdi-account', color: 'danger' },
      { id: 2, value: 11549, title: this.$t('dashboard.visitCountMonth'), class: 'mdi-account', color: 'blue' },
      { id: 3, value: 11549, title: this.$t('dashboard.bookingCountToday'), class: 'mdi-cursor-default', color: 'danger' },
      { id: 4, value: 11549, title: this.$t('dashboard.bookingCountMonth'), class: 'mdi-cursor-default', color: 'blue' },
    ];

    this.favoriteBlocks = [
      { id: 1, value: 11549, title: this.$t('dashboard.favoriteCountToday'), class: 'mdi-heart', color: 'danger' },
      { id: 2, value: 11549, title: this.$t('dashboard.favoriteCountMonth'), class: 'mdi-heart', color: 'blue' },
    ];

    const labels = getDatesArrayInterval(DateTime.local().minus({ month: 1 }), DateTime.local(), MD_FORMAT);
    this.incomeChartData = {
      labels,
      datasets: [{
        label: '$ 1 231 549',
        fill: true,
        data: labels.map(() => Math.floor(Math.random() * 1000)),
        borderColor: '#625CD5',
        backgroundColor: '#E3E1F7',
        pointBackgroundColor: '#fff',
        pointBorderColor: '#625CD5',
        pointBorderWidth: 2,
      }]
    }
  }
}
