import { Component, Prop, Vue } from 'vue-property-decorator';
import { Line } from 'vue-chartjs';
import { Chart, ChartData, ChartOptions } from 'chart.js';

Chart.plugins.register({
  id: 'paddingAfterLegends',
  beforeInit: function (chart: any) {
    chart.legend.afterFit = function () {
      this.height = this.height + 10;
    }
  }
});

const COLOR_LIGHT = '#dee2e6';
const COLOR_GREY = '#98a6ad';

@Component({
  extends: Line
} as any)
export default class LineChart extends Vue<Line> {
  @Prop({
    required: true
  })
  chartData!: ChartData;

  @Prop({
    required: false
  })
  options!: ChartOptions;

  @Prop({
    required: false
  })
  backgroundColor!: string;

  @Prop({
    type: Number,
    required: false
  })
  height?: number;

  defaultOptions: ChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      paddingAfterLegends: true
    },
    legend: {
      align: 'start',
      labels: {
        usePointStyle: true,
        boxWidth: 9
      }
    },
    elements: {
      line: {
        fill: false,
        tension: 0
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          color: COLOR_LIGHT,
        },
        ticks: {
          fontColor: COLOR_GREY,
          fontSize: 11
        }
      }],
      yAxes: [{
        gridLines: {
          color: COLOR_LIGHT,
        },
        ticks: {
          fontColor: COLOR_GREY,
          min: 0,
          beginAtZero: true,
          callback: (label: number) => Math.floor(label) === label ? label : ''
        },
        afterFit: () => 1050
      }]
    }
  };

  mounted() {
    const canvas = this.$refs.canvas as HTMLCanvasElement;
    canvas.style.backgroundColor = this.backgroundColor;

    if (this.height) {
      canvas.height = this.height;
    }

    this.renderChart(this.chartData, {
      ...this.defaultOptions,
      ...this.options
    });
  }
}
